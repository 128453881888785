// Helpers that get/set values from Cloudfront Cookies.

import uuidv4 from 'uuid/v4'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

// eslint-disable-next-line import/prefer-default-export
export const getSessionId = () => {
  const sessionId = cookies.get('__Host-rtgp')
  const devSessionId = uuidv4()
  if (sessionId) {
    return sessionId
  }
  if (cookies && cookies.set) {
    cookies.set('__Host-rtgp', devSessionId, { path: '/', secure: true, sameSite: 'lax' })
  }
  return devSessionId
}

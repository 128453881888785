import PropTypes from 'prop-types'
import React from 'react'
import InfoModal from '@shared/info-modal'
import StrapiMarkdown from '@templates/strapi-cms/content-types/Markdown'

import { graphql, StaticQuery } from 'gatsby'

const BedsToGoModal = ({ shouldShowModal, closeModal }) => (
  <InfoModal label="Details" shouldShowModal={shouldShowModal} closeModal={closeModal}>
    <StaticQuery
      query={graphql`
        query BedsToGoModalQuery {
          strapiMarkdown(Title: { eq: "Beds To Go \"100 Night Sleep Trial\" Modal" }) {
            ...StrapiPageMarkdownFragment
          }
        }
      `}
      render={data => <StrapiMarkdown data={data.strapiMarkdown} />}
    />
  </InfoModal>
)

BedsToGoModal.propTypes = {
  closeModal: PropTypes.any,
  shouldShowModal: PropTypes.any,
}

export default BedsToGoModal

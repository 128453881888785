import _get from 'lodash/get'
import _each from 'lodash/each'
import _isNil from 'lodash/isNil'
import _isEmpty from 'lodash/isEmpty'

const scriptsForLoad = {}

const loadScript = (url, props = {}, reload = false) => {
  if (!_get(scriptsForLoad, [url], false) || reload === true) {
    scriptsForLoad[url] = new Promise((resolve, reject) => {
      const script = document.createElement('script')

      _each(props, (value, key) => {
        if (!_isNil(value) && !_isEmpty(value)) {
          script.setAttribute(key, value)
        }
      })
      Object.assign(script, {
        type: 'text/javascript',
        src: url,
        async: true,
        onload: () => {
          resolve(script)
        },
        onerror: err => {
          reject(err)
        },
      })
      document.head.appendChild(script)
    })
  }
  return scriptsForLoad[url]
}

export default loadScript

import { getFullStateName } from '@helpers/geo-location'

const eligibleServiceContractStates = [
  'Florida',
  'FL',
  'Georgia',
  'GA',
  'Alabama',
  'AL',
  'Mississippi',
  'MS',
  'North Carolina',
  'NC',
  'South Carolina',
  'SC',
  'Virginia',
  'VA',
  'Tennessee',
  'TN',
  'Louisiana',
  'LA',
  'Texas',
  'TX',
]

export const getStateEligiblity = state => {
  const stateName = state || getFullStateName()

  return eligibleServiceContractStates.some(
    eligibleStateName => eligibleStateName.toLowerCase() === stateName.toLowerCase(),
  )
}

export default getStateEligiblity

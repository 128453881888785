import { store } from '@redux/store'
import { setOrder } from '@redux/modules/checkout'
import { sentryLogger, levels, sentryMessages, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { updateAddress, updatePayment } from '@services/checkout'
import { getOrder, checkManualAddress } from '../global'
import { getInfoWithGiftAndFinance } from './payment-section'
import { abbreviateState } from '../../geo-location'
import { validateZip } from '../../string-helper'
import { getAddressSpecificBody } from '../shipping-section'

export const setBillingAddressInfo = (info, field = null) => {
  let order = getOrder()
  let billingInfo = info
  if (info.state) {
    info.state = abbreviateState(info.state)
  } else if (field === 'state') {
    // eslint-disable-next-line no-param-reassign
    billingInfo = abbreviateState(info)
  }
  if (field) {
    order = {
      ...order,
      billingAddress: {
        ...order.billingAddress,
        [field]: billingInfo,
      },
    }
  } else {
    order = {
      ...order,
      billingAddress: {
        ...order.billingAddress,
        ...info,
      },
    }
  }
  store.dispatch(setOrder(order))
}

export const validateBillingAddress = async order => {
  const entries = Object.entries({ ...order.payer, ...order.billingAddress })
  let invalidFields = []
  for (let i = 0; i < entries.length; i++) {
    const entryKey = entries[i][0]
    const entryData = entries[i][1]
    if ((entryData === '' || entryData === null) && entryKey !== 'address2') {
      if (entryKey === 'address1') {
        invalidFields.push('address1')
      } else {
        invalidFields.push(entryKey)
      }
    } else if (entryKey === 'zip' && !validateZip(entryData)) {
      invalidFields.push('zip')
    }
  }
  if (invalidFields.length < 1) {
    invalidFields = await checkManualAddress(order, invalidFields, true)
  }
  return invalidFields
}

// eslint-disable-next-line
export let submitBillingAddress = setBillingState => {
  const order = getOrder()
  setBillingState({ invalidFields: [], loading: true })
  updateAddress(getAddressSpecificBody(order, true))
    .then(orderResponse => {
      let newPaymentInfo = []
      const giftFin = getInfoWithGiftAndFinance()
      if (giftFin && giftFin.length > 0) {
        newPaymentInfo = giftFin
      }
      updatePayment({
        paymentInfo: newPaymentInfo,
        orderId: orderResponse.orderId,
      })
        .then(data => {
          store.dispatch(
            setOrder({
              ...data,
              payer: {
                ...data.payer,
                billingDifferent: true,
                billingSubmitted: true,
              },
              acceptManual: false,
            }),
          )
          setBillingState({ loading: false, invalidFields: [] })
        })
        .catch(() => {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.error,
              order: cleanOrderObject(order),
            },
            captureMessage: {
              type: 'text',
              message: 'Checkout - Billing update payment',
              level: levels.error,
            },
          })
          setBillingState({ loading: false })
        })
    })
    .catch(() => {
      sentryLogger({
        configureScope: {
          type: setExtra,
          level: levels.error,
          orderId: order.orderId,
          section: 'Checkout: Payment Section - Billing Address',
        },
        captureMessage: {
          message: sentryMessages.updateAddressFailure,
          level: levels.error,
        },
      })
      setBillingState({ loading: false })
    })
}

export const onChangeBillingDifferent = (event, orderInput) => {
  const order = {
    ...orderInput,
    payer: {
      ...orderInput.payer,
      billingDifferent: !event.target.checked,
    },
  }
  if (order && order.payer) {
    if (order.payer.billingSubmitted) {
      store.dispatch(
        setOrder({
          ...order,
          cyberSourceSignature: null,
        }),
      )
      updateAddress(getAddressSpecificBody(order, !event.target.checked))
        .then(() => {
          let newPaymentInfo = []
          const giftCards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
          if (giftCards.length > 0) {
            newPaymentInfo = giftCards
          }
          updatePayment({
            paymentInfo: newPaymentInfo,
            orderId: order.orderId,
          })
            .then(data => {
              store.dispatch(
                setOrder({
                  ...data,
                  payer: {
                    ...order.payer,
                    ...data.payer,
                  },
                }),
              )
            })
            .catch(() => {
              sentryLogger({
                configureScope: {
                  type: setExtra,
                  level: levels.info,
                  order: cleanOrderObject(order),
                },
                captureMessage: {
                  message: 'Checkout - Billing Address update payment',
                  level: levels.info,
                },
              })
            })
        })
        .catch(err => {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.error,
              orderId: order.orderId,
              error: err,
              section: 'Checkout: Payment Section - Billing Address Change',
            },
            captureMessage: {
              message: sentryMessages.updateAddressFailure,
              level: levels.error,
            },
          })
        })
    } else {
      store.dispatch(setOrder(order))
    }
  }
}

export const onBillingStateChange = event => {
  if (event.target.value !== 'none') {
    setBillingAddressInfo(event.target.value, 'state')
  }
}

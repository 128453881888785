import Cookies from 'universal-cookie'
import { navigate } from 'gatsby'
import * as Sentry from '@sentry/browser'
import { sentryLogger, levels, setExtra, cleanOrderObject, sentryMessages } from '@helpers/sentry-logger'
import { store } from '@redux/store'
import { setOrder, setReviewInvalidFields, setDeclineModalInfo, showCouponInvalidModal } from '@redux/modules/checkout'
import { clearCart } from '@redux/modules/cart'
import { placeOrder as placeOrderAPI } from '@services/checkout'
import { removeLocalStorage } from '@helpers/storage'
import { getOrder, clearCheckoutState } from './global'
import { getCart } from '../cart'
import { placeOrderAnalytics } from '../google-tag-manager'
import { redactCCFromOrder } from './payment-section/credit-card'

const cookies = new Cookies()

export const setReviewInfo = (info, field) => {
  const order = getOrder()
  store.dispatch(
    setOrder({
      ...order,
      reviewInfo: {
        ...order.reviewInfo,
        [field]: info,
      },
    }),
  )
}

export const validateReviewStep = async () => {
  const order = getOrder()
  const invalidFields = []

  const valid = () => {
    const entries = Object.entries(order.reviewInfo)
    const requireWarrantyCheck = getCartsLegalRequirements(order)
    const validCheck = []
    let requiredLength = 0

    Object.keys(requireWarrantyCheck).map((key, index) => {
      requiredLength = index + 1

      for (let i = 0; i < entries.length; i++) {
        if (key.toString() === entries[i][0].toString() && entries[i][1]) {
          validCheck.push(key)
        }
      }
      return true
    })
    return validCheck.length === requiredLength
  }

  if (!valid()) {
    invalidFields.push('accept')
  }
  store.dispatch(setReviewInvalidFields(invalidFields))
  return invalidFields
}

export const placeOrderReview = async () => {
  const order = getOrder()
  const cart = getCart()
  const TL_RTG = cookies.get('__Host-TL_RTG')
  const session = cookies.get('__Host-rtgp')
  const { deliveryMode } = store.getState().checkout
  let isThankyouCouponOrder = false
  try {
    const res = await placeOrderAPI(order.orderId, session, TL_RTG)
    if (res) {
      if (res.thankYouId) {
        removeLocalStorage('coupon')
        removeLocalStorage('couponType')
        isThankyouCouponOrder = true
      }
      placeOrderAnalytics(res, isThankyouCouponOrder, order, deliveryMode)
      store.dispatch(
        setOrder({
          ...order,
          orderCart: { ...cart },
        }),
      )
      store.dispatch(clearCart())
      sentryLogger({
        configureScope: {
          type: setExtra,
          placeOrder: redactCCFromOrder(order),
        },
        captureMessage: {
          type: 'text',
          message: `Checkout Success`,
        },
      })
      Sentry.captureMessage(`Checkout Success`)
      // TODO: navigation need to add code to track ga data
      navigate(`/order/success/${order.orderId}`)
    }
  } catch (err) {
    const errorMessage = err?.response?.data?.error?.message
    const newOrder = {
      ...order,
      reviewInfo: {
        acceptTerms: false,
        acceptPickupTerms: false,
        acceptServiceContractTerms: false,
        acceptSevenYearTerms: false,
      },
    }
    if (errorMessage && order?.paymentInfo) {
      if (
        errorMessage.toLowerCase().includes('credit card was declined') &&
        order.paymentInfo.filter(payment => payment.paymentType === 'CYBERV3').length > 0
      ) {
        store.dispatch(
          setDeclineModalInfo({
            declineModalOpen: true,
            declineCloseLoading: false,
            declineType: 'credit card',
            paymentType: 'CYBERV3',
          }),
        )
        store.dispatch(setOrder(newOrder))
      } else if (
        errorMessage.toLowerCase().includes('this paypal order was not authorized') &&
        order.paymentInfo.filter(payment => payment.paymentType === 'PALV2').length > 0
      ) {
        store.dispatch(
          setDeclineModalInfo({
            declineModalOpen: true,
            declineCloseLoading: false,
            declineType: 'paypal',
            paymentType: 'PALV2',
          }),
        )
        store.dispatch(setOrder(newOrder))
      } else if (
        errorMessage.toLowerCase().includes('credit card payment decline') &&
        order.paymentInfo.filter(payment => payment.paymentType === 'APP').length > 0
      ) {
        store.dispatch(
          setDeclineModalInfo({
            declineModalOpen: true,
            declineCloseLoading: false,
            declineType: 'apple pay',
            paymentType: 'APP',
          }),
        )
        store.dispatch(setOrder(newOrder))
      } else if (
        errorMessage.toLowerCase().includes('rge declined authorization') &&
        order.paymentInfo.filter(payment => payment.paymentType === 'FIN').length > 0
      ) {
        store.dispatch(
          setDeclineModalInfo({
            declineModalOpen: true,
            declineCloseLoading: false,
            declineType: 'Rooms To Go financing',
            paymentType: 'FIN',
          }),
        )
        store.dispatch(setOrder(newOrder))
      } else if (
        err.response.data.error.message &&
        order.paymentInfo.filter(payment => payment.paymentType === 'DBUY').length > 0
      ) {
        store.dispatch(
          setDeclineModalInfo({
            declineModalOpen: true,
            declineCloseLoading: false,
            declineType: 'Rooms To Go financing',
            paymentType: 'DBUY',
          }),
        )
        store.dispatch(setOrder(newOrder))
      } else if (errorMessage.toLowerCase().includes('thank you coupon')) {
        // handle invalid thank you coupon
        store.dispatch(showCouponInvalidModal())
        removeLocalStorage('coupon')
        removeLocalStorage('couponType')
      } else if (
        // handle expired delivery date
        errorMessage.toLowerCase().includes('does not have a delivery date')
      ) {
        store.dispatch(
          setDeclineModalInfo({
            declineModalOpen: true,
            declineCloseLoading: false,
            declineType: 'Invalid delivery date',
          }),
        )
      } else {
        store.dispatch(
          setDeclineModalInfo({
            reviewInvalidFields: ['order'],
            declineCloseLoading: false,
          }),
        )
        store.dispatch(setOrder(newOrder))
      }
      sentryLogger({
        configureScope: {
          type: setExtra,
          level: levels.info,
          order: cleanOrderObject(order),
        },
        captureMessage: {
          type: 'text',
          message: sentryMessages.paymentDenial,
          level: levels.info,
        },
      })
    }
    sentryLogger({
      configureScope: {
        type: setExtra,
        level: levels.fatal,
        order: cleanOrderObject(order),
      },
      captureMessage: {
        type: 'text',
        message: sentryMessages.orderFailure,
        level: levels.fatal,
      },
    })
  }
}

export const getFormattedCurrentDate = () =>
  new Date()
    .toDateString()
    .split(' ')
    .slice(1)
    .join(',')

export const getFormattedDate = dateString =>
  new Date(dateString)
    .toDateString()
    .split(' ')
    .slice(1)
    .join(',')

/* Warranty helpers */

export const orderHasSomeWarranty = order =>
  order && order.lineItems && order.lineItems.some(item => item.warrantyEnabled)

export const checkSevenYear = warranty => {
  if (warranty && (warranty.plan_name === 'RTGLOSADJBEDI7YD' || warranty.plan_name === 'R7ABA')) {
    return true
  }
  return false
}

export const getCartsLegalRequirements = order => {
  const entries = Object.entries(order.reviewInfo)

  const requiredCheckboxes = { [entries[0][0]]: 'TOC' }
  if (order && order.lineItems) {
    if (order.isPickup) {
      requiredCheckboxes[entries[1][0]] = 'Pickup'
    }

    for (let i = 0; i < order.lineItems.length; i++) {
      const item = order.lineItems[i]
      if (item.warrantyEnabled && typeof item.warrantyPrice === 'object') {
        if (!checkSevenYear(item.warrantyPrice)) {
          requiredCheckboxes[entries[2][0]] = item.warrantyPrice.provider || item.warrantyPrice.provider_name
        } else {
          requiredCheckboxes[entries[3][0]] = item.warrantyPrice.provider || item.warrantyPrice.provider_name
        }
      } else if (item.warrantyEnabled && !item.warrantyPrice) {
        sentryLogger({
          configureScope: {
            type: setExtra,
            placeOrder: order,
          },
          captureMessage: {
            type: 'text',
            message: `StoreCart - warrantyEnabled without warrantyPrice`,
          },
        })
      }
    }
  }
  return requiredCheckboxes
}

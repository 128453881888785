const getDeliveryExpressAnalytics = ({ data, deliveryCalendar }) => {
  const { isPickup, deliveryDate } = data
  let deliveryExpress = 'standard'

  if (deliveryCalendar && deliveryDate && !isPickup) {
    deliveryCalendar.forEach((day, index) => {
      if (day.date === deliveryDate && day.isExpressDelivery) {
        deliveryExpress = `express${index + 1}`
      }
    })
  }
  return deliveryExpress
}

export default getDeliveryExpressAnalytics

/* eslint-disable no-useless-escape */
/*
. disabled because if I remove them, the regex no longer
. work in this file and cause tests to fail
 */

exports.genKey = () => Math.floor(Math.random() * 100000)
exports.isString = obj => Object.prototype.toString.call(obj) === '[object String]'

/*
  Ex: isWholeWordInStr('red', 'upholstered') = false | isWholeWordInStr('red', 'upholstered/color/red') = true
*/
exports.isWholeWordInStr = (str, word) => {
  let cleanedWord = word
  if (!cleanedWord.match(/[~\-]/i)) {
    cleanedWord = cleanSearchUrl(word)
  } // if not already cleaned
  return decodeURIComponent(str).match(`(^|[^a-z])(${cleanedWord})([^a-z]|$)`, 'i')
}

exports.distinct = (value, index, self) => self.indexOf(value) === index

exports.getLastParamOfPath = () => {
  if (typeof window !== 'undefined') {
    const pathSplit = removeFirstAndLastSlash(window.location.pathname).split('/')
    return pathSplit ? pathSplit[pathSplit.length - 1] : ''
  }
  return ''
}

exports.paramsIncludeKey = key => {
  if (window) {
    const pathSplit = window.location.pathname.split('/')
    return pathSplit ? pathSplit.includes(key) : false
  }
  return false
}

exports.titleCase = (str, splitters = [' ']) => {
  let lowerCaseStr = str.toLowerCase()
  for (let x = 0; x < splitters.length; x++) {
    const strArr = lowerCaseStr.split(splitters[x])
    for (let y = 0; y < strArr.length; y++) {
      strArr[y] = strArr[y].charAt(0).toUpperCase() + strArr[y].slice(1)
    }
    lowerCaseStr = strArr.join(splitters[x])
  }
  return lowerCaseStr
}

exports.removeCamelCase = str =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
    .toLowerCase()

exports.slugGenerator = (category, sub_category = null, title = null, sku = null) => {
  let slugPath = `/${slugify(category, { lower: true })}`
  if (sub_category) {
    slugPath = `${slugPath}/${slugify(sub_category, { lower: true })}`
  }
  if (title) {
    slugPath = `${slugPath}/${slugify(title, { lower: true })}`
  }
  if (sku) {
    slugPath = `${slugPath}/${slugify(sku)}`
  }
  return slugPath
}

exports.decodeHtml = html => {
  if (typeof document === 'undefined') return ''
  const txt = document.createElement('textarea')
  txt.innerHTML = html
  return txt.value
}

/* 
  Temporary solution to decoding the trademark html entity through javascript
*/
exports.decodeTrademarkEntity = html => html.replace(/&trade;/g, '™')

const slugify = string => {
  const a = 'àáäâãåăæçèéëêǵḧìíïîḿńǹñòóöôœṕŕßśșțùúüûǘẃẍÿź·/_,:;'
  const b = 'aaaaaaaaceeeeghiiiimnnnoooooprssstuuuuuwxyz------'
  const p = new RegExp(a.split('').join('|'), 'g')
  return string
    ?.toString()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-')
    ?.replace(p, c => b.charAt(a.indexOf(c)))
    ?.replace(/&/g, '-and-')
    ?.replace(/[^[\w\"]\-]+/g, '')
    ?.replace(/\-\-+/g, '-')
    ?.replace(/^-+/, '')
    ?.replace(/-+$/, '')
    ?.replace(/["']/g, '-')
}
exports.slugify = slugify

exports.currencyFormatUS = (price, forceCents = false) => {
  let priceNumber = Number(price)
  if (forceCents || !Number.isInteger(priceNumber)) {
    priceNumber = priceNumber.toFixed(2)
  } else {
    priceNumber = priceNumber.toFixed(0)
  }
  return `$${priceNumber}`
}

exports.validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

exports.validatePhone = phone => {
  const re = /^[2-9]{1}?[0-9]{2}?[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/
  return re.test(String(phone).toLowerCase())
}

exports.validateZip = zip => {
  const re = /(^\d{5}$)|(^\d{5}-\d{4}$)/
  return re.test(String(zip).toLowerCase())
}

exports.validatePoBox = add => {
  const re = /(?:p\.?\s*o\.?)\s+box/ // po box, p.o. box, po. box, p.o box
  return (
    String(add)
      .toLowerCase()
      .match(re) === null
  )
}

const cleanSearchUrl = string =>
  string
    .replace(/-/g, '~')
    .replace(/\s/g, '-')
    .replace(/_family/g, '')
    .trim()
exports.cleanSearchUrl = cleanSearchUrl

exports.cleanSearchParamValue = string => string.replace(/-/g, ' ').replace(/~/g, '-')

exports.stripHtml = string => string.replace(/(<([^>]+)>)/gi, '')

// TODO: Convert all dependencies to use locale string format

exports.months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

exports.abbreviatedMonths = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']

exports.weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

exports.weekdaysLong = short => {
  const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  let index
  switch (short) {
    case 'Sun':
      index = 0
      break
    case 'Mon':
      index = 1
      break
    case 'Tue':
      index = 2
      break
    case 'Wed':
      index = 3
      break
    case 'Thu':
      index = 4
      break
    case 'Fri':
      index = 5
      break
    case 'Sat':
      index = 6
      break
    default:
      index = 0
      break
  }
  return weekdays[index]
}

const removeFirstAndLastSlash = string => string.replace(/^\/|\/$/g, '')
exports.removeFirstAndLastSlash = removeFirstAndLastSlash

exports.getStandardTime = time => {
  const timeArr = time.split(':')
  const hours = Number(timeArr[0])
  const minutes = Number(timeArr[1])
  let timeValue
  if (hours > 0 && hours <= 12) {
    timeValue = `${hours}`
  } else if (hours > 12) {
    timeValue = `${hours - 12}`
  } else if (hours === 0) {
    timeValue = '12'
  }
  timeValue += minutes < 10 ? `:0${minutes}` : `:${minutes}`
  const period = hours >= 12 ? 'pm' : 'am'
  return `${timeValue}${period}`
}

exports.getTimeRange = (start, end) => {
  /* eslint-disable prefer-const */
  let [startHour, startMinute] = start.split(':')
  let [endHour, endMinute] = end.split(':')
  const arr = []
  startHour = Number(startHour) + (startMinute === '00' ? 0 : 0.5)
  endHour = Number(endHour) + (endMinute === '00' ? 0 : 0.5)

  for (let i = startHour; i <= endHour; i += 0.5) {
    const time = Number.isInteger(i) ? '00' : '30'
    arr.push(`${Math.floor(i)}:${time}`)
  }
  return arr
}

exports.letters = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]

exports.getUrlParams = url => {
  const param = {}
  url.replace(/[?&]([^=#]+)=([^&#]*)/g, (m, key, value) => {
    const nKey = decodeURIComponent(key)
    const nValue = decodeURIComponent(value)
    param[nKey] = nValue
  })
  return param // object
}

exports.getAddressString = address =>
  `${address.address1} ${address?.address2 || ''}, ${address.city} ${address.state} ${address.zip}`

exports.getNumbersOnly = value => value.replace(/[^0-9]/g, '')

import { getOrder } from '../global'

export const redactCCFromOrder = order => {
  const reportOrder = order
  if (reportOrder && reportOrder.paymentInfo) {
    let cc = reportOrder.paymentInfo.filter(payment => payment.paymentType === 'CYBERV3')
    if (cc && cc.length > 0) {
      const newCC = cc[0]
      cc = newCC
      if (cc.paymentProperties && cc.paymentProperties.token) {
        reportOrder.paymentInfo.filter(payment => payment.paymentType === 'CYBERV3')[0].paymentProperties = {}
      }
    }
  }
  return reportOrder
}

export const getCreditCardDecision = (orderIn = null) => {
  let decision = false
  let order
  if (orderIn) {
    order = orderIn
  } else {
    order = getOrder()
  }
  if (order && order.paymentInfo && order.paymentInfo.length > 0) {
    const credit = order.paymentInfo.filter(payment => payment.paymentType === 'CYBERV3')[0]
    if (credit && credit.paymentProperties && credit.paymentProperties.token) {
      decision = true
    }
  }
  return decision
}

export const scrollTo = element => {
  setTimeout(() => {
    if (document.getElementById(element)) {
      window.scroll({
        top:
          document.getElementById(element)?.offsetTop - 25 - document.getElementsByClassName('head')?.[0]?.offsetHeight,
      })
    }
  }, 1000)
}

import { useState, useEffect } from 'react'
import { string } from 'prop-types'
import _get from 'lodash/get'
import loadScript from '@helpers/loadScript'
import { useLocation } from '@gatsbyjs/reach-router'
import { emailSignup } from '@services/email'

export const Segment = ({ apiKey }) => {
  const [segmentLoaded, setSegmentLoaded] = useState(false)
  const analytics = typeof window !== 'undefined' && _get(window, 'analytics')
  const { pathname } = useLocation()

  useEffect(() => {
    if (apiKey) {
      const url = `https://cdn.segment.com/analytics.js/v1/${apiKey}/analytics.min.js`
      loadScript(url)
        .then(() => setSegmentLoaded(true))
        // eslint-disable-next-line no-console
        .catch(err => console.log(`Loading segment failed from ${url}. ${JSON.stringify(err)}`))
    }
  }, [apiKey])

  useEffect(() => {
    if (segmentLoaded && analytics) analytics.page()
  }, [segmentLoaded, analytics, apiKey, pathname])

  return null
}

export const getAnonymousId = () =>
  typeof window.analytics?.user === 'function' && window?.analytics?.user?.()?.anonymousId?.()
export const getUserId = async data =>
  emailSignup({
    anonymousId: getAnonymousId(),
    email: data?.email,
    subsourcecode: data?.subsourcecode,
    zipcode: data?.zipcode,
    signup: data?.signup ? String(data?.signup) : 'false',
    userid: 'rtg-web-user',
    sourcecode: 'ONLINE',
    sourcekey: 'ONLINE',
    storenumber: '970', // 970 is the specific store number for e-commerce
  })
export const identifyUser = async data => {
  if (typeof window.analytics?.identify === 'function') {
    getUserId(data).then(response => identityApi(response?.userId, { email: data?.email, zipcode: data?.zipcode }))
  }
}
export const identityApi = (userId, data) => window.analytics?.identify(userId, { ...data }, () => {})

Segment.propTypes = {
  apiKey: string,
}

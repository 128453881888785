import { store } from '@redux/store'
import { setOrder, setPaymentInvalidFields } from '@redux/modules/checkout'
import { getOrder } from '../global'
import { getGiftCards } from './gift-cards'
import { validateZip, validateEmail } from '../../string-helper'

export const canShowBilling = order => {
  let showBilling
  const orderValues = getRemainingTotal(true, null, true)
  const cyber =
    order &&
    order.paymentInfo &&
    order.paymentInfo.filter(payment => payment.paymentType === 'CYBERV3' || payment.paymentType === 'GIFT')
  const giftCards = getGiftCards()
  if (
    cyber &&
    cyber.length > 0 &&
    cyber[0].paymentProperties &&
    (cyber[0].paymentProperties.token || cyber[0].paymentProperties.pin)
  ) {
    showBilling = true
  } else {
    showBilling =
      orderValues.total >= 0 &&
      giftCards.length !==
        (order && order.paymentInfo && order.paymentInfo.filter(payment => payment.paymentType !== 'CYBERV3').length)
  }
  return showBilling
}

export const getCreditCardType = order => {
  let cardType
  if (order && order.paymentInfo) {
    const credit = order.paymentInfo.filter(payment => payment.paymentType === 'CYBERV3')[0]
    if (credit?.paymentProperties?.card?.cardType) {
      cardType = credit.paymentProperties.card.cardType
    }
  }
  return cardType
}

export const getActualPaymentType = payment => {
  if (payment) {
    const cc = payment.paymentType === 'CYBERV3'
    if (cc) {
      if (cc && payment.paymentProperties && payment.paymentProperties.token) return 'Credit'
    }
    const paypal = payment.paymentType === 'PALV2'
    if (paypal) return 'PayPal'
    const visa = payment.paymentType === 'VISA'
    if (visa) return 'Visa Checkout'
    const affirm = payment.paymentType === 'AFF'
    if (affirm) return 'Affirm'
    const fin = payment.paymentType === 'FIN'
    if (fin) return 'Rooms To Go Finance'
    const gen = payment.paymentType === 'GEN'
    if (gen) return 'Genesis'
    const applePay = payment.paymentType === 'APP'
    if (applePay) return 'Apple Pay'
    const digitalBuy = payment.paymentType === 'DBUY'
    if (digitalBuy) return 'Digital Buy'
    const bitpay = payment.paymentType === 'bitpay'
    if (bitpay) return 'bitpay'
    const klarna = payment.paymentType === 'KLN'
    if (klarna) return 'Klarna'
    if (payment.paymentType === 'GIFT') return 'Gift Card'
  }
  return ''
}

export const getPaymentCondensedAddressInfo = (order, actualPaymentType) => {
  let addressInfo = {
    firstName: order.contact.firstName,
    lastName: order.contact.lastName,
    address1: order.shippingAddress.address1,
    address2: order.shippingAddress.address2,
    city: order.shippingAddress.city,
    state: order.shippingAddress.state,
    zip: order.shippingAddress.zip,
  }
  if (
    (order.payer.billingDifferent && order.payer.billingSubmitted) ||
    actualPaymentType === 'PayPal' ||
    actualPaymentType === 'Apple Pay' ||
    actualPaymentType === 'Digital Buy'
  ) {
    addressInfo = {
      firstName: order.payer.firstName,
      lastName: order.payer.lastName,
      address1: order.billingAddress.address1,
      address2: order.billingAddress.address2,
      city: order.billingAddress.city,
      state: order.billingAddress.state,
      zip: order.billingAddress.zip,
    }
  }
  return addressInfo
}

export const getRemainingTotal = (
  deliveryComplete = true,
  prevTotal = null,
  isCheckout = false,
  applyFinance = true,
  shippingComplete = true,
) => {
  const order = getOrder()
  let total = 0
  let appliedGiftCards = 0
  let appliedFinance = 0
  if (prevTotal) {
    total = prevTotal
  }
  if (order && order.total && shippingComplete && isCheckout) {
    total = order.total
    if (!deliveryComplete && order.tax) {
      total -= order.tax
    }
    if (deliveryComplete) {
      if (order.paymentInfo && order.paymentInfo.length > 0) {
        const giftCards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
        if (giftCards.length > 0 && giftCards[0].authorizedAmount > 0) {
          for (let i = 0, n = giftCards.length; i < n; i++) {
            appliedGiftCards += giftCards[i].authorizedAmount
          }
          total -= appliedGiftCards
        }
        const rtgFin = order.paymentInfo.filter(
          payment => payment.paymentType === 'FIN' || payment.paymentType === 'DBUY',
        )
        if (rtgFin.length > 0 && applyFinance) {
          appliedFinance = rtgFin[0].authorizedAmount
          total -= appliedFinance
        }
      }
    }
  }
  return {
    total,
    gift: appliedGiftCards,
    fin: appliedFinance,
    tax: order && order.tax,
    deliveryCost: order && order.totalDeliveryCharge,
    totalSavings: order && order.promotions && order.promotions.totalSavings,
  }
}

export const getInfoWithGiftAndFinance = () => {
  const order = getOrder()
  let giftFin
  if (order && order.paymentInfo) {
    giftFin = order.paymentInfo.filter(
      payment =>
        (payment.paymentType === 'GIFT' ||
          payment.paymentType === 'FIN' ||
          payment.paymentType === 'GEN' ||
          payment.paymentType === 'DBUY') &&
        payment.paymentProperties,
    )
  }
  return giftFin
}

export const setPayerInfo = (info, field) => {
  let order = getOrder()
  if (field) {
    order = {
      ...order,
      payer: {
        ...order.payer,
        [field]: info,
      },
    }
  } else {
    order = {
      ...order,
      payer: {
        ...order.payer,
        ...info,
      },
    }
  }
  store.dispatch(setOrder(order))
}

// eslint-disable-next-line
export let validatePaymentStep = nextStep => {
  const order = getOrder()
  const invalidFields = []
  if (order && order.payer && order.payer.billingDiffferent && nextStep === 'review') {
    const entries = Object.entries({
      ...order.billingAddress,
      ...order.payer,
    })
    for (let i = 0; i < entries.length; i++) {
      const entryKey = entries[i][0]
      const entryData = entries[i][1]
      if (
        (entryData === '' || entryData === null) &&
        entryKey !== 'address2' &&
        entryKey !== 'phone' &&
        entryKey !== 'email'
      ) {
        invalidFields.push(entryKey)
      } else {
        if (entryKey === 'email' && !validateEmail(entryData)) {
          invalidFields.push('email')
        }
        if (entryKey === 'zip' && !validateZip(entryData)) {
          invalidFields.push('zip')
        }
      }
    }
  }
  let validated = true
  if ((order && order.amountDue > 0 && nextStep === 'review') || invalidFields.length > 0) {
    validated = false
  }
  if (!invalidFields.length && !validated) {
    invalidFields.push('A payment has not been submitted.')
  }
  store.dispatch(setPaymentInvalidFields(invalidFields))
  return invalidFields
}

export const shouldShowPayments = order => {
  if (order && order.total && order.paymentInfo && order.paymentInfo.length > 0) {
    let appliedPayments = 0
    const giftCards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
    if (giftCards.length > 0 && giftCards[0].authorizedAmount) {
      for (let i = 0, n = giftCards.length; i < n; i++) {
        appliedPayments += giftCards[i].authorizedAmount
      }
    }
    if (order.total <= appliedPayments) {
      return !order.giftCardInfo.useGiftCard
    }
    return true
  }
  return true
}

export const removeKlarnaSession = () => {
  sessionStorage.removeItem('klarna_auth_total')
  sessionStorage.removeItem('klarna_session_id')
  sessionStorage.removeItem('klarna_session_token')
}

import Cookies from 'universal-cookie'
import Axios from 'axios'
import { store } from '@redux/store'
import { regionLocations } from '@mocks/locationDataMocks'

const cookies = new Cookies()

export const setDefaultLocation = rtg_location_cookie => {
  const { GATSBY_RTG_REGION } = process.env
  let location = rtg_location_cookie
  if (GATSBY_RTG_REGION === 'PR' && rtg_location_cookie?.region !== 'PR') {
    location = regionLocations.PR
  }
  if (GATSBY_RTG_REGION === 'PR') {
    location.state = 'Puerto Rico'
  }
  return location
}

export const getGeocode = location => {
  const currentRegion = getRegionZone().region
  const country = currentRegion === 'PR' ? 'PR' : 'US' // adjusted for Puerto Rico
  let cityStateOrZip = location
  if (
    country === 'PR' &&
    typeof cityStateOrZip === `string` &&
    !cityStateOrZip.match(/^[0-9]*$/) &&
    !cityStateOrZip.match(',')
  ) {
    cityStateOrZip += ', PR'
  }
  const url =
    typeof location === `object`
      ? `https://maps.google.com/maps/api/geocode/json?latlng=${location.lat},${location.lng}&key=${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`
      : `https://maps.google.com/maps/api/geocode/json?address=${cityStateOrZip}&components=country:${country}&key=${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`
  return Axios.get(url)
}

export const getCurrentLocation = serverCookie => {
  const location =
    serverCookie?.get('__Host-rtg_location') ||
    cookies.get('__Host-rtg_location') ||
    store.getState().location.rtg_location
  return setDefaultLocation(location)
}

export async function geoLocation(address, reload = true) {
  let geocode = null
  let error = false
  try {
    geocode = await getGeocode(address)
  } catch (err) {
    error = true
    return error
  }
  const primaryLocation = geocode.data.results[0]
  if (
    !primaryLocation ||
    (primaryLocation &&
      !(primaryLocation.formatted_address.includes('USA') || primaryLocation.formatted_address.includes('Puerto Rico')))
  ) {
    error = true
    return error
  }
  const postal_code_comp = primaryLocation.address_components.find(comp => comp.types.includes('postal_code'))
  let state = primaryLocation.address_components.find(comp => comp.types.includes('administrative_area_level_1'))
  let county = primaryLocation.address_components.find(comp => comp.types.includes('administrative_area_level_2'))
  const country = primaryLocation.address_components.find(comp => comp.types.includes('country'))
  state = state && state.long_name
  county = county && county.long_name.substr(0, county.long_name.length - 7)
  let city = primaryLocation.address_components.find(comp => comp.types.includes('locality'))
  if (country && country.short_name === 'PR') {
    state = 'PR' // hardcode always
    if (!county) {
      county = 'PR'
    }
  }

  // For PR site, if someone enters US zip codes, show an error on the site. if needed, redirect the user to .com site.
  if (process.env.GATSBY_RTG_DIVISION_SUPPORTED === 'PR' && state !== process.env.GATSBY_RTG_DIVISION_SUPPORTED) {
    error = true
    return error
  }

  city = city && city.short_name
  let postal_code = ''
  if (postal_code_comp) {
    postal_code = postal_code_comp.short_name
  } else if (!postal_code_comp && !city) {
    error = true
    return error
  } else {
    const { location } = geocode.data.results[0].geometry
    geocode = await getGeocode(location)
    geocode.data.results.forEach(item => {
      if (postal_code === '') {
        try {
          postal_code = item.address_components.find(comp => comp.types.includes('postal_code')).short_name
        } catch (e) {}
      }
    })
  }

  const latitude = geocode.data.results[0].geometry.location.lat
  const longitude = geocode.data.results[0].geometry.location.lng

  cookies.remove('__Host-rtg_region', { path: '/', secure: true, sameSite: 'lax' })
  cookies.remove('rtg_region', { path: '/', secure: true, sameSite: 'lax' })
  cookies.set('__Host-rtg_zip', postal_code, { path: '/', secure: true, sameSite: 'lax' })
  if (process.env.NODE_ENV === 'development') {
    const rtg_location = cookies.get('__Host-rtg_location') ?? {}
    cookies.remove('__Host-rtg_location')
    rtg_location.city = city || ''
    rtg_location.state = state
    rtg_location.county = county
    rtg_location.zip = postal_code
    // Development zip code OOM
    const postal_int = parseInt(postal_code)
    if (postal_int === 80202) {
      rtg_location.region = 'OOM'
    } else if (
      postal_int === 73301 ||
      (postal_int >= 75001 && postal_int <= 79999) ||
      (postal_int >= 88510 && postal_int <= 88589)
    ) {
      rtg_location.region = 'TX'
    } else if (postal_int >= 32004 && postal_int <= 34997) {
      rtg_location.region = 'FL'
    } else if (state === 'PR') {
      rtg_location.region = 'PR'
      rtg_location.lat = latitude
      rtg_location.long = longitude
    } else {
      rtg_location.region = 'SE'
    }
    cookies.set('__Host-rtg_location', rtg_location, { path: '/', secure: true, sameSite: 'lax' })
  }
  if (reload) {
    window.location.reload()
  }
  return error
}

/**
 * Returns 5 digit area code if zip can be extraced from location
 * input via getGeocode, else returns null
 * @param {string} address
 * @returns {string}
 */

export async function getZipFromSearch(address) {
  let postal_code = null
  const geocode = await getGeocode(address)
  const primaryLocation = geocode.data.results[0]
  if (!primaryLocation || (primaryLocation && !primaryLocation.formatted_address.includes('USA'))) {
    return postal_code
  }
  const postal_code_comp = primaryLocation.address_components.find(comp => comp.types.includes('postal_code'))
    ?.short_name
  if (postal_code_comp) {
    postal_code = postal_code_comp
  }
  const { location } = geocode.data.results[0].geometry
  const preciseLocation = await getGeocode(location)
  preciseLocation.data.results.forEach(item => {
    if (postal_code === null) {
      try {
        item.address_components.forEach(comp => {
          if (comp.types.includes('postal_code')) {
            postal_code = comp.short_name
          }
        })
      } catch (e) {}
    }
  })
  return postal_code
}

export const getLatLng = async zip => {
  let geocode = null
  let error = false
  try {
    geocode = await getGeocode(zip)
  } catch (err) {
    error = true
    return error
  }
  const primaryLocation = geocode.data.results[0]
  if (!primaryLocation) {
    error = true
    return error
  }
  return {
    lat: primaryLocation.geometry.location.lat,
    lng: primaryLocation.geometry.location.lng,
  }
}

export const getRegionZone = () => {
  const rtg_location = getCurrentLocation()
  return {
    region:
      rtg_location && typeof rtg_location.region !== 'undefined' ? rtg_location.region : process.env.GATSBY_RTG_REGION,
    zone: rtg_location && typeof rtg_location.price_zone !== 'undefined' ? rtg_location.price_zone : 0,
  }
}

export const getRegion = () => {
  const rtg_location = getCurrentLocation()
  return rtg_location && typeof rtg_location.region !== 'undefined'
    ? rtg_location.region
    : process.env.GATSBY_RTG_REGION
}

export const getZip = () => cookies.get('__Host-rtg_zip') || cookies.get('__Host-rtg_location')?.zip

export const getFullStateName = () => {
  const rtg_location = getCurrentLocation()
  return rtg_location && typeof rtg_location.state !== 'undefined' ? rtg_location.state : 'Florida'
}

export const abbreviateState = stateInput => {
  let state = stateInput
  if (state) {
    state = state.trim()
    if (state.length < 3) {
      state = state.toUpperCase()
      for (let i = 0; i < states.length; i++) {
        if (states[i][1] === state) {
          return state
        }
      }
      return null
    }
    state = state.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase())
    for (let i = 0; i < states.length; i++) {
      if (states[i][0] === state) {
        return states[i][1]
      }
    }
  }
  return null
}

export const expandState = state => states.find(([full, short]) => short === state)?.[0] || ''

export const states = [
  ['Arizona', 'AZ'],
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['District Of Columbia', 'DC'],
  ['Delaware', 'DE'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
]

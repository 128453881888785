import React from 'react'
import InfoModal from '@shared/info-modal'
import StrapiMarkdown from '@templates/strapi-cms/content-types/Markdown'
import { graphql, StaticQuery } from 'gatsby'
import { func, bool } from 'prop-types'

const CasperModal = ({ shouldShowModal, closeModal }) => (
  <InfoModal label="Details" shouldShowModal={shouldShowModal} closeModal={closeModal}>
    <StaticQuery
      query={graphql`
        query CasperModalQuery {
          strapiMarkdown(Title: { eq: "Casper \"100 Night Sleep Trial\" Modal" }) {
            ...StrapiPageMarkdownFragment
          }
        }
      `}
      render={data => <StrapiMarkdown data={data.strapiMarkdown} />}
    />
  </InfoModal>
)

CasperModal.propTypes = {
  shouldShowModal: bool,
  closeModal: func,
}

export default CasperModal

const formatMsg = msg => `<div aria-live="assertive" aria-atomic="true">${msg}</div>`

export const taskDone = (fn, time, id) => {
  const timers = {}
  if (typeof fn === 'function') {
    if (timers[id]) clearTimeout(timers[id])
    timers[id] = setTimeout(fn, time)
  }
}

export const announce = (msg, form, ele, delay) => {
  let message = msg
  if (typeof ele === 'object') {
    message += ele.innerText.replace(/\n/g, ' ')
  }
  if (document) {
    if (document.getElementById('aria-announce')) {
      document.getElementById('aria-announce').innerHTML = ''
    }
    taskDone(
      () => {
        document.getElementById('aria-announce').innerHTML = formatMsg(message)
      },
      delay || 500,
      'announceMessage',
    )
    if (form) {
      taskDone(
        () => {
          form.querySelectorAll('[aria-invalid="true"]')[0].focus()
        },
        1000,
        'focusElement',
      )
    }
  }
}

const axios = require('axios')

export const fetchProductBySku = sku =>
  axios.get(`${process.env.GATSBY_AWS_PRODUCT_URL}${sku.toUpperCase()}.json`).then(response => response.data)

export const fetchProductWarehouseAvailability = (sku, distributionIndex, state) => {
  if (sku && distributionIndex && state) {
    return axios
      .get(
        `${process.env.GATSBY_AVAILABILITY_SERVICE_URL}/availability?skus=${sku}&distributionIndex=${distributionIndex}&state=${state}`,
      )
      .then(response => response.data)
  }
  return Promise.reject(
    new Error(`SKU, distributionIndex, and state must be provided to get product availability.
    Received SKU: ${sku} distributionIndex: ${distributionIndex} state: ${state}`),
  )
}

export const getSeeInStore = (sku, zip) =>
  axios
    .get(`${process.env.GATSBY_SEEINSTORE_SERVICE_URL}/v1/stores?sku=${sku}&zipcode=${zip}`)
    .then(response => response.data)

// dependencies
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { makeStyles, Grid, Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  markdownWrapper: {
    textAlign: ({ Alignment }) => Alignment || 'unset',
    maxWidth: ({ Width }) => (Width ? `${Width}rem` : null),
    paddingLeft: ({ PaddingLeft }) => (PaddingLeft ? `${PaddingLeft}px` : theme.spacing(1)),
    paddingRight: ({ PaddingRight }) => (PaddingRight ? `${PaddingRight}px` : theme.spacing(1)),
    paddingBottom: ({ PaddingBottom }) => (PaddingBottom ? `${PaddingBottom}px` : theme.spacing(1)),
    paddingTop: ({ PaddingTop }) => (PaddingTop ? `${PaddingTop}px` : theme.spacing(1)),
    '& h1, & h2, & h3, & h4, & h5': {
      fontWeight: 600,
      color: theme.palette.text.primary,
      paddingLeft: 0,
    },
    '& h1': {
      fontSize: '2.2rem',
    },
    '& h2': {
      fontSize: '2rem',
    },
    '& h3': {
      fontSize: '1.8rem',
    },
    '& h4': {
      fontSize: '1.6rem',
    },
    '& h5': {
      fontSize: '1.25rem',
    },
    '& h6': {
      fontSize: '1rem',
    },
    '& b': {
      fontWeight: 600,
    },
    '& ul': {
      listStyle: 'square',
      marginLeft: '2rem',
      padding: '1rem 0',
    },
    '& ol': {
      listStyle: 'decimal',
      marginLeft: '2rem',
      padding: '1rem 0',
    },
    '& em': {
      fontStyle: 'italic',
    },
    '& strong': {
      fontWeight: 600,
    },
    '& img': {
      [theme.breakpoints.down('sm')]: {
        maxWidth: '55%',
      },
    },
    '& a': {
      color: theme.palette.primary.main,
      textDecoration: 'underline',
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
}))

const StrapiMarkdown = ({ data, Alignment = null, Width = null, gridWrapper = true, ...props }) => {
  const padding = {
    PaddingTop: data?.PaddingTop,
    PaddingBottom: data?.PaddingBottom,
    PaddingLeft: data?.PaddingLeft,
    PaddingRight: data?.PaddingRight,
  }
  const classes = useStyles({ Alignment, Width, ...padding })
  const __html = data?.Markdown?.Markdown?.childMarkdownRemark?.html || data?.Markdown?.childMarkdownRemark?.html

  if (!__html) return null

  if (!gridWrapper) {
    return (
      <Box {...props} data-testid={data?.Markdown?.id}>
        <div className={`${classes.markdownWrapper} strapi-markdown`} dangerouslySetInnerHTML={{ __html }} />
      </Box>
    )
  }

  return (
    <Grid item md={12} {...props} data-testid={data?.Markdown?.id}>
      <div className={`${classes.markdownWrapper} strapi-markdown`} dangerouslySetInnerHTML={{ __html }} />
    </Grid>
  )
}

StrapiMarkdown.propTypes = {
  data: PropTypes.object.isRequired,
  gridWrapper: PropTypes.bool,
  Alignment: PropTypes.string,
  Width: PropTypes.number,
}

export default StrapiMarkdown

export const StrapiPageMarkdownFragment = graphql`
  fragment StrapiPageMarkdownFragment on StrapiMarkdown {
    id
    Type
    Title
    Markdown {
      id
      childMarkdownRemark {
        html
      }
    }
  }
`

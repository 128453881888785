import { store } from '@redux/store'
import { setOrder } from '@redux/modules/checkout'
import { updatePayment } from '@services/checkout'
import { sentryLogger, levels, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { hasIn } from 'lodash'
import { getOrder } from '../global'

export const getGiftCards = orderInput => {
  const order = orderInput || getOrder()
  const giftCards = []
  if (order && order.paymentInfo) {
    const cards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
    if (cards && cards.length > 0) {
      for (let i = 0, n = cards.length; i < n; i++) {
        if (cards[i].paymentProperties) {
          giftCards.push({
            giftCardNumber: cards[i].paymentProperties.cardNumber,
            giftCardPin: cards[i].paymentProperties.pin,
            authorizedAmount: cards[i].authorizedAmount,
            balance: cards[i].paymentProperties.balance,
          })
        }
      }
    }
  }
  return giftCards
}

export const setOrderGiftCardInfo = (info, field) => {
  let order = getOrder()
  if (field) {
    order = {
      ...order,
      giftCardInfo: {
        ...order.giftCardInfo,
        [field]: info,
      },
    }
  } else {
    order = {
      ...order,
      giftCardInfo: {
        ...order.giftCardInfo,
        ...info,
      },
    }
  }
  store.dispatch(setOrder(order))
}

export const validateGiftCard = (order, setGiftCardState = null) => {
  const invalidFields = []
  if (order.giftCardInfo.giftCardNumber === '' || order.giftCardInfo.giftCardNumber.length !== 16) {
    invalidFields.push('giftCardNumber')
  }
  if (order.giftCardInfo.giftCardPin === '') {
    invalidFields.push('giftCardPin')
  }
  if (setGiftCardState) setGiftCardState(invalidFields, 'invalidFields')
  return invalidFields.length < 1
}

export const onApplyGiftCard = (order, setGiftCardState) => {
  setGiftCardState(
    {
      invalidFields: [],
      unableToAddMessage: '',
    },
    null,
    () => {
      const valid = validateGiftCard(order, setGiftCardState)
      if (valid) {
        setGiftCardState(true, 'loading')
        let appliedAlready = false
        const giftCards = getGiftCards(order)
        const giftCardsExist = giftCards.length > 0

        if (giftCardsExist) {
          for (let i = 0, n = giftCards.length; i < n; i++) {
            if (giftCards[i].giftCardNumber === order.giftCardInfo.giftCardNumber) {
              appliedAlready = true
            }
          }
        }

        if (!appliedAlready) {
          const orderPaymentInfo = [
            {
              paymentType: 'GIFT',
              paymentProperties: {
                cardNumber: order.giftCardInfo.giftCardNumber,
                pin: order.giftCardInfo.giftCardPin,
              },
            },
          ]
          if (order.paymentInfo && order.paymentInfo.length > 0) {
            const filteredGiftCards = order.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
            if (filteredGiftCards.length > 0) {
              for (let i = 0, n = filteredGiftCards.length; i < n; i++) {
                orderPaymentInfo.unshift(filteredGiftCards[i])
              }
            }
          }
          updatePayment({
            orderId: order.orderId,
            paymentInfo: orderPaymentInfo,
          })
            .then(data => {
              store.dispatch(setOrder(data))
              const giftCardInfo = getGiftCards(data)
              const filteredGiftCards = data.paymentInfo.filter(payment => payment.paymentType === 'GIFT')
              const orderCard = filteredGiftCards.filter(
                card =>
                  card.paymentProperties.cardNumber &&
                  card.paymentProperties.cardNumber === giftCardInfo.giftCardNumber,
              )[0]
              if (orderCard && orderCard.paymentProperties) {
                const newOrderInfo = {
                  ...order,
                  giftCardInfo: {
                    giftCards: giftCardInfo,
                    addAnotherCard: false,
                    giftCardNumber: '',
                    giftCardPin: '',
                    useGiftCard: true,
                  },
                }
                store.dispatch(setOrder(newOrderInfo))
                setGiftCardState({
                  unableToAddMessage: '',
                  loading: false,
                })
              } else {
                setGiftCardState(false, 'loading')
              }
            })
            .catch(err => {
              let errorMessage = 'Unable to add card'
              if (hasIn(err, 'response.request.responseText')) {
                const returnedErrorMessage = JSON.parse(err.response.request.responseText).error.message

                if (returnedErrorMessage === 'Can not use a gift card to pay for a gift card.') {
                  errorMessage = returnedErrorMessage
                }
                if (returnedErrorMessage.match('Bonus Gift Card')) {
                  errorMessage = returnedErrorMessage
                }
              }

              setGiftCardState({
                unableToAddMessage: errorMessage,
                loading: false,
              })

              sentryLogger({
                configureScope: {
                  type: setExtra,
                  level: levels.info,
                  order: cleanOrderObject(order),
                },
                captureMessage: {
                  type: 'text',
                  message: 'Checkout - Gift Card update payment',
                  level: levels.info,
                },
              })
            })
        } else {
          setGiftCardState({
            unableToAddMessage: 'Gift card has already been applied.',
            loading: false,
          })
        }
      }
    },
  )
}

export const onAddAnotherCard = order => {
  if (order) {
    const newOrderInfo = {
      ...order,
      giftCardInfo: {
        ...order.giftCardInfo,
        addAnotherCard: true,
      },
    }
    store.dispatch(setOrder(newOrderInfo))
  }
}

export const onChangeUseGiftCard = (order, checked) => {
  setOrderGiftCardInfo({
    useGiftCard: checked,
    addAnotherCard: false,
  })
  if (checked) {
    const giftCards = getGiftCards(order)
    if (order && giftCards && giftCards.length > 0) {
      const newGiftCards = []
      for (let i = 0, n = giftCards.length; i < n; i++) {
        newGiftCards.push({
          paymentType: 'GIFT',
          paymentProperties: {
            cardNumber: giftCards[i].giftCardNumber,
            pin: giftCards[i].giftCardPin,
          },
        })
      }
      updatePayment({
        paymentInfo: newGiftCards,
        orderId: order.orderId,
      })
        .then(data => {
          store.dispatch(setOrder(data))
        })
        .catch(() => {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.error,
              order: cleanOrderObject(order),
            },
            captureMessage: {
              type: 'text',
              message: 'Checkout - Gift Card update payment onchange',
              level: levels.error,
            },
          })
        })
    }
  } else if (order.selectedPaymentType === 'Credit') {
    store.dispatch(
      setOrder({
        ...order,
        giftCardInfo: {
          ...order.giftCardInfo,
          useGiftCard: false,
          addAnotherCard: false,
        },
        cyberSourceSignature: null,
      }),
    )
  }
}

export const onRemoveGiftCard = (order, giftCard, setGiftCardState) => {
  setGiftCardState(true, 'removing')
  const giftCards = getGiftCards(order)
  if (order && order.paymentInfo && giftCards) {
    store.dispatch(
      setOrder({
        ...order,
        cyberSourceSignature: null,
      }),
    )

    const paymentInfo = order.paymentInfo.filter(
      payment => payment?.paymentProperties?.cardNumber !== giftCard?.giftCardNumber,
    )

    updatePayment({
      orderId: order.orderId,
      paymentInfo,
    })
      .then(data => {
        const newGiftCards = getGiftCards(data)
        const updatedOrder = {
          ...data,
          giftCardInfo: {
            ...order.giftCardInfo,
            giftCards: newGiftCards,
            giftCardNumber: '',
            giftCardPin: '',
            addAnotherCard: false,
            useGiftCard: newGiftCards.length > 0,
          },
        }
        store.dispatch(setOrder(updatedOrder))
        setGiftCardState(false, 'removing')
      })
      .catch(() => {
        sentryLogger({
          configureScope: {
            type: setExtra,
            level: levels.error,
            order: cleanOrderObject(order),
            paymentType: 'PALV2',
          },
          captureMessage: {
            type: 'text',
            message: 'Checkout - Gift Cards remove card',
            level: levels.error,
          },
        })
      })
  }
}

import { expandState } from '@helpers/geo-location'
import { weekdays } from './string-helper'
// eslint-disable-next-line import/no-unresolved
import storeHoursFeed from '../generators/data/store-hours-feed.json'

import { getFromBrowserStorage } from './storage'

export const getStoreSlug = store => {
  const { City, StoreName, StoreType, StoreNumber, State } = store
  const expandedState = expandState(State)
  const name = StoreName ? `-${StoreName}` : ''
  const type = StoreType ? `-${StoreType}` : ''
  const number = StoreNumber ? `-${StoreNumber}` : ''
  return `/stores/${expandedState || State}/${City}${name + type + number}`
    .replace(/\s+/g, '-')
    .toLowerCase()
    .trim()
}

export const getMarkers = (currentLat, currentLng, storeLocs) => {
  const markers = []
  const stores = storeLocs.filter(store => store.StoreTypeId !== 'W')
  for (let i = 0; i < stores.length; i++) {
    stores[i].distance = calculateDistance(
      currentLat,
      currentLng,
      stores[i].latitude || stores[i].Location.latLng.lat,
      stores[i].longitude || stores[i].Location.latLng.lng,
    )
  }
  stores.sort((a, b) => a.distance - b.distance)
  stores.forEach((store, index) => {
    const storeDataFromFeed = getStoreInfoFromFeed(store.StoreNumber)
    if (storeDataFromFeed && storeDataFromFeed.store_hours && index < 26) {
      markers.push({
        title: store.Address1,
        slug: getStoreSlug(store),
        name: getStoreName(store),
        lat: store.Location.latLng.lat,
        lng: store.Location.latLng.lng,
        city: store.City,
        state: store.State,
        storeNumber: store.StoreNumber || store.storeNumber,
        StoreNumber: store.StoreNumber || store.storeNumber,
        zip: store.Zip,
        phone: storeDataFromFeed.phone,
        hours: getStoreHours(storeDataFromFeed.store_hours),
        distance: Math.round(store.distance * 10) / 10,
      })
    }
  })
  return markers
}

export const getStoreName = store => {
  if (store.Name && store.StoreType) {
    return `${store.City} - ${store.Name} ${store.StoreType}`
  }
  return `${store.City} ${store.StoreType}`
}

export const getStoreHours = store => {
  let day = []
  const hours = []
  for (let i = 0, n = weekdays.length; i < n; i++) {
    day = weekdays[i]
    hours.push({
      day,
      openTime: store[`${day.toLowerCase()}Open`],
      closeTime: store[`${day.toLowerCase()}Closed`],
    })
  }
  return hours
}

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  if (lat1 === lat2 && lon1 === lon2) {
    return 0
  }
  const radlat1 = (Math.PI * lat1) / 180
  const radlat2 = (Math.PI * lat2) / 180
  const theta = lon1 - lon2
  const radtheta = (Math.PI * theta) / 180
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta)
  if (dist > 1) {
    dist = 1
  }
  return ((Math.acos(dist) * 180) / Math.PI) * 60 * 1.1515 * 0.8684
}

export const getStore = setOrder => {
  const order = setOrder || getFromBrowserStorage('session', 'order')
  if (order && order.distributionIndex) {
    return getStoreInfoFromFeed(order.distributionIndex)
  }
  return null
}

export const getStoreInfoFromFeed = storeNum => {
  if (storeNum && storeHoursFeed && storeHoursFeed[storeNum]) {
    return storeHoursFeed[storeNum]
  }
  return null
}

export const createStoreDetailPath = ({ StoreName, City, State, StoreType, StoreNumber }) => {
  let nodePath
  if (StoreName) {
    nodePath = `/stores/${expandState(State)
      .toLowerCase()
      .replace(' ', '-')}/${City.toLowerCase().replace(' ', '-')}-${StoreName.toLowerCase().replace(
      ' ',
      '-',
    )}-${StoreType.toLowerCase().replace(' ', '-')}-${StoreNumber}`
  } else {
    nodePath = `/stores/${expandState(State)
      .toLowerCase()
      .replace(' ', '-')}/${City.toLowerCase().replace(' ', '-')}-${StoreType.toLowerCase().replace(
      ' ',
      '-',
    )}-${StoreNumber}`
  }
  return nodePath
}

import { getRoomsToGoDeliveryItems } from '@helpers/checkout/delivery-section'
import { getRegionZone } from '@helpers/geo-location'
import { getWarrantyDataByRegionZone, productPrice } from '@helpers/product'

export const checkoutStepsOrIndex = step => {
  const checkoutSteps = ['shipping', 'delivery', 'payment', 'review']
  return step ? checkoutSteps.indexOf(step) + 1 : checkoutSteps
}

export const getTaxes = order => {
  const { tax } = order
  return tax > -1 ? tax : -2
}

export const getDeliveryCost = order => {
  const { totalDeliveryCharge, isPickup, lineItems } = order
  return totalDeliveryCharge > 0 ||
    isPickup ||
    (lineItems && lineItems.length && getRoomsToGoDeliveryItems(lineItems).length >= 0)
    ? totalDeliveryCharge
    : -1
}

export const getPromoDescriptions = order => {
  const { promotions } = order
  const promoDescriptions = []
  if (promotions && promotions.promotions) {
    for (let i = 0, n = promotions.promotions.length; i < n; i++) {
      promoDescriptions.push({
        desc: promotions.promotions[i].description,
        amount: promotions.promotions[i].amount,
      })
    }
  }
  return promoDescriptions
}

export const checkPromotionsForNewTotal = (order, cartTotal) => {
  const { promotions } = order
  return promotions && promotions.totalSavings && promotions.cartTotal ? promotions.cartTotal : cartTotal
}

export const getOrderDiscount = (discount, order, isCheckout) => {
  let orderDiscount = discount
  if (isCheckout) {
    orderDiscount = order && order.promotions && order.promotions.totalSavings
  }
  return orderDiscount
}

const handleSubtotalAddons = (item, regionZone) => {
  // temp fix to handle weird subtotal logic
  const { activeAddons, quantity, price, product, warrantyEnabled } = item
  const warrantyPrice = () => getWarrantyDataByRegionZone(product.warranty_pricing, regionZone).price
  let newSubtotal

  if (warrantyEnabled && product && activeAddons) {
    const addOnsPrice = activeAddons.map(addOn => productPrice(addOn.pricing)).reduce((acc, curr) => acc + curr)
    newSubtotal = price + addOnsPrice + warrantyPrice() * quantity
  } else if (!warrantyEnabled && product && activeAddons) {
    const addOnsPrice = activeAddons.map(addOn => productPrice(addOn.pricing)).reduce((acc, curr) => acc + curr)
    newSubtotal = price + addOnsPrice * quantity
  } else if (warrantyEnabled && product) {
    newSubtotal = price + warrantyPrice() * quantity
  } else {
    newSubtotal = price * quantity
  }

  return newSubtotal
}

export const getSubtotalPrice = (cart, storeCart) => {
  const cartItems = cart && cart.cartItems
  const storeCartItems =
    (storeCart && storeCart.length) || (storeCart && storeCart.lineItems && storeCart.lineItems.length)
  const regionZone = getRegionZone()
  let subtotal = 0
  if (cartItems) {
    cart.cartItems.forEach(item => {
      subtotal += handleSubtotalAddons(item, regionZone)
    })
  }
  if (storeCartItems) {
    if (storeCart.length) {
      storeCart.forEach(item => {
        subtotal += item.warrantyEnabled
          ? item.unitPrice + item.warrantyPrice.price * item.quantity
          : item.unitPrice * item.quantity
      })
    } else {
      storeCart.lineItems.forEach(item => {
        subtotal += item.warrantyEnabled
          ? item.unitPrice + item.warrantyPrice.price * item.quantity
          : item.unitPrice * item.quantity
      })
    }
  }
  return subtotal
}

export const getGrandTotal = ({ subTotal, thisDeliveryCost, thisTax, orderDiscount, isCheckout }) => {
  let grandTotal = 0
  if (isCheckout) {
    if (subTotal && thisDeliveryCost >= 0 && !thisTax) {
      grandTotal = subTotal + thisDeliveryCost
    } else if (subTotal && thisDeliveryCost >= 0 && thisTax >= 0) {
      grandTotal = subTotal + thisDeliveryCost + thisTax
    } else grandTotal = subTotal
    return orderDiscount ? grandTotal - orderDiscount : grandTotal
  }
  return subTotal
}

export const setSessionItem = (key, value) => {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export const getSessionItem = key => {
  const value = window.sessionStorage.getItem(key)
  return value && JSON.parse(value)
}

import { Buffer } from 'buffer/'
import crypto from 'crypto'
import { getOrder } from '../global'
import { getRemainingTotal } from './payment-section'

export const getOrderTotal = total => {
  const order = getOrder()
  let apTotal = order.total
  if (order && order.orderId && (total > 0 || order.total > 0)) {
    const applyFinance =
      order &&
      order.paymentInfo &&
      order.paymentInfo.filter(
        payment =>
          payment.paymentType === 'FIN' ||
          (payment.paymentType === 'DBUY' && payment.paymentProperties && payment.paymentProperties.hasPayments),
      ).length > 0
    apTotal = Math.floor((getRemainingTotal(true, null, true, applyFinance).total || total) * 100) / 100
  }
  return apTotal
}

export const scrollTo = element => {
  setTimeout(() => {
    if (document.getElementById(element) && document.getElementsByTagName('header')) {
      window.scroll({
        top:
          document.getElementById(element)?.offsetTop - 25 - document.getElementsByTagName('header')?.[0]?.offsetHeight,
      })
    }
  }, 1000)
}

export const decryptFinancePlans = (uvfinancePlan, encryptedFinancePlans) => {
  const split = uvfinancePlan.split('|')
  let synchronyCode = split[0]
  if (encryptedFinancePlans) {
    const decipher = crypto.createDecipheriv(
      process.env.GATSBY_FINANCE_CODE_ENCRYPTION_ALGORITHM,
      Buffer.from(process.env.GATSBY_FINANCE_CODE_ENCRYPTION_KEY),
      process.env.GATSBY_FINANCE_CODE_ENCRYPTION_IV,
    )
    const encryptedCodes = Buffer.from(encryptedFinancePlans, process.env.GATSBY_FINANCE_CODE_ENCRYPTION_RADIX)
    let decryptedCodes = Buffer.concat([decipher.update(encryptedCodes), decipher.final()])
    decryptedCodes = decryptedCodes.toString().split('|')
    synchronyCode = decryptedCodes.length > 1 ? decryptedCodes[1] : decryptedCodes[0]
  }
  return synchronyCode
}

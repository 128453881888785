import PropTypes from 'prop-types'
import React from 'react'
import Modal from 'react-modal'
import '@comp-sass/product/product-parts/add-to-cart-modal.sass'

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#___gatsby')

const InfoModal = props => {
  const { shouldShowModal, mdlClass, label, closeModal, children } = props
  return (
    <Modal
      isOpen={shouldShowModal}
      contentLabel={label}
      className={`info-modal add-to-cart-modal ${mdlClass}`}
      onRequestClose={closeModal}
      overlayClassName="modal-overlay"
    >
      <div className="modal-content">
        <div className="grid-x">
          <div className="text-container small-12">{children}</div>
          <button
            type="button"
            className="close-modal"
            tabIndex="0"
            value="Close"
            aria-label="Close"
            onClick={closeModal}
          >
            <img
              className="icon close"
              alt="close icon"
              src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23FFFFFF' d='M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z' /%3E%3C/svg%3E"
            />
          </button>
        </div>
      </div>
    </Modal>
  )
}

InfoModal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.any,
  label: PropTypes.any,
  mdlClass: PropTypes.any,
  shouldShowModal: PropTypes.any,
}

export default InfoModal

const axios = require('axios')

export const emailSignup = body =>
  axios.post(`${process.env.GATSBY_EMAIL_SERVICE_URL}/signup/`, body).then(response => response.data)

export const unsubscribeEmail = email =>
  axios
    .get(`${process.env.GATSBY_EMAIL_UNSUBSCRIBE}`, {
      headers: { 'x-api-key': process.env.GATSBY_EMAIL_UNSUBSCRIBE_KEY },
      params: {
        email,
      },
    })
    .then(response => response.data)

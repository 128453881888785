function formatDate(date) {
  return new Date(new Date(date).toUTCString().substr(0, 25))
}

export function getDateFull(date) {
  const formattedDate = formatDate(date)
  const isSameYear = formattedDate.getFullYear() === new Date().getFullYear()
  const dateString = formattedDate.toLocaleString('en-us', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })
  return isSameYear ? dateString.slice(0, -6) : dateString
}

export function getDateCalendarFormat(date) {
  const newDate = new Date(date)
  return newDate ? `${newDate.getUTCMonth() + 1}/${newDate.getUTCDate()}/${newDate.getUTCFullYear()}` : date
}

export function createDateWithTimeZone(timeZone, year, month, day, hour, minute, second) {
  const date = new Date(Date.UTC(year, month, day, hour, minute, second))

  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }))
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone }))
  const offset = utcDate.getTime() - tzDate.getTime()

  date.setTime(date.getTime() + offset)

  return date
}
